$(document).on('click', '.js-btn-detail', function (e) {
  e.preventDefault();
  let id = $(this).data('item-id');
  let cl = $(this).data('item-cl');

  if(cl == 'users') {
    cl = 'feedbacks';
  }

  $.ajax({
    url: '/' + cl + '/' + id,
    method: 'GET',
    data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
  }).done(function () {
  });
});

$(document).on('click', '.js-btn-login-histories', function (e) {
  e.preventDefault();
  let id = $(this).data('item-id');
  let cl = $(this).data('item-cl');

  if(cl == 'users') {
    cl = 'user_managements';
  }

  $.ajax({
    url: `/${cl}/login-histories?id=${id}`,
    method: 'GET',
    data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
  }).done(function () {
  });
});
