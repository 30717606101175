$(document).on('click', '.js-btn-add-notification-management', function (e) {
  e.preventDefault();

  $.ajax({
    url: '/notification_managements/new',
    method: 'GET',
    data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
  }).done(function () {
  });
});

$(document).on('click', '.js-btn-edit-notification-management', function (e) {
  e.preventDefault();
  let id = $(this).data('item-id');

  $.ajax({
    url: '/notification_managements/' + id + '/edit',
    method: 'GET',
    data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
  }).done(function () {
  });
});
