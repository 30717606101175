$(document).on('click', '.js-custom-confirm', function (e) {
  e.preventDefault();

  let item_id = $(this).data('item-id');
  let item_cl = $(this).data('item-cl');
  let close_dialog = 'キャンセル';
  let submit_dialog = '削除';
  let title = '削除しますか？';

  $('#confirm-modal').find('p.message-content').html(title);
  $('#confirm-modal').find('.close-dialog').html(close_dialog);
  $('#confirm-modal').find('.submit-dialog').html(submit_dialog);
  $('#confirm-modal').find('.logout-btn').addClass('d-none');
  $('#confirm-modal').find('.submit-dialog').removeClass('d-none');

  $('#confirm-modal').data('item-id', item_id);
  $('#confirm-modal').data('item-class', item_cl);

  $('#confirm-modal').modal('show');
});

$(document).on('click', '#confirm-modal .submit-dialog', function (e) {
  e.preventDefault();

  let id = $(this).closest('#confirm-modal').data('item-id');
  let cl = $(this).closest('#confirm-modal').data('item-class');

  if(cl == 'setting_configs') {
    cl = 'content_managements';
  }

  let url = '/' + cl + '/' + id;

  $.ajax({
    url: url,
    method: 'DELETE',
    data: { 'authenticity_token': $('meta[name="csrf-token"]').attr('content') }
  }).done(function() {
  });
});
