import $ from 'jquery'

$(document).on('ready turbolinks:load', function () {
  initSidebarMenuState();

  $(document).on('click', '.current-pwd-hint-icon', function (e) {
    e.preventDefault();

    $(".current-password-hint").removeClass("d-none");
    $(".current-pwd-input-field").addClass("d-none");
    $('.current-pwd-hint-icon').addClass("d-none");
    $(".current-pwd-unhint-icon").removeClass("d-none");
  });

  $(document).on('click', '.current-pwd-unhint-icon', function (e) {
    e.preventDefault();

    $(".current-password-hint").addClass("d-none");
    $(".current-pwd-input-field").removeClass("d-none");
    $('.current-pwd-hint-icon').removeClass("d-none");
    $(".current-pwd-unhint-icon").addClass("d-none");
  });

  $(document).on('click', '.pwd-hint-icon', function (e) {
    e.preventDefault();

    $(".password-hint").removeClass("d-none");
    $(".pwd-input-field").addClass("d-none");
    $('.pwd-hint-icon').addClass("d-none");
    $(".pwd-unhint-icon").removeClass("d-none");
  });

  $(document).on('click', '.pwd-unhint-icon', function (e) {
    e.preventDefault();

    $(".password-hint").addClass("d-none");
    $(".pwd-input-field").removeClass("d-none");
    $('.pwd-hint-icon').removeClass("d-none");
    $(".pwd-unhint-icon").addClass("d-none");
  });

  $(document).on('click', '.pwd-confirm-hint-icon', function (e) {
    e.preventDefault();

    $(".pwd-confirm-hint-icon").addClass("d-none");
    $(".pwd-confirm-unhint-icon").removeClass("d-none");
    $(".password-confirmation-hint").toggleClass("d-none");
    $(".pwd-confirm-input-field").toggleClass("d-none");
  });

  $(document).on('click', '.pwd-confirm-unhint-icon', function (e) {
    e.preventDefault();

    $(".pwd-confirm-hint-icon").removeClass("d-none");
    $(".pwd-confirm-unhint-icon").addClass("d-none");
    $(".password-confirmation-hint").toggleClass("d-none");
    $(".pwd-confirm-input-field").toggleClass("d-none");
  });

  $('input.current-pwd-input-field, input.current-password-hint').keyup(function () {
    var currentValue = $(this).val();
    $(this).closest(".form-group").find(".d-none").val(currentValue);
  });

  $('input.pwd-input-field, input.password-hint').keyup(function () {
    var currentValue = $(this).val();
    $(this).closest(".form-group").find(".d-none").val(currentValue);
  });

  $('input.pwd-confirm-input-field, input.password-confirmation-hint').keyup(function () {
    var currentValue = $(this).val();
    $(this).closest(".form-group").find(".d-none").val(currentValue);
  });

  $(document).on('click', '.js-success-btn', function(e){
    e.preventDefault();

    $('.js-success-modal').on('hidden', function () {
      $(this).remove();
    });

    var url = $(this).attr("href");
    location.href = url;
  })
});

$(document).on('click', '.sidebar-dropdown-btn', function (e) {
  let elements = $(this).parents('.sidebar').find('.sidebar-dropdown-btn').not($(this));

  for (let i = 0; i < elements.length; i++) {
    if ($(elements[i]).hasClass('active')) {
      closeSidebarItemState(elements[i]);
    }
  }

  handleSidebarMenuState($(this));
});

function closeSidebarItemState($object) {
  $($object).removeClass('active');

  $($object).find('img').addClass('filter-c-blue');
  $($object).find('span.float-end img').addClass('filter-c-chevron-left');
  $($object).find('span.float-end img').removeClass('rotated-180');
  $($object).find('span.float-end img').addClass('rotated-90');

  let dropdownContent = $($object).siblings(".sidebar-dropdown-container")[0];

  $(dropdownContent).hide('slow');
}

function openSidebarItemState($object, dropdown_content_items = null, active_item = null) {
  $($object).addClass('active');

  $($object).find('img').removeClass('filter-c-blue');
  $($object).find('span.float-end img').removeClass('filter-c-chevron-left');
  $($object).find('span.float-end img').removeClass('rotated-90');
  $($object).find('span.float-end img').addClass('rotated-180');

  let dropdownContent = $($object).siblings(".sidebar-dropdown-container")[0];

  if (dropdown_content_items) {
    for (let j = 0; j < dropdown_content_items.length; j++) {
      $(dropdown_content_items[j]).removeClass('active');
    }

    $(active_item).addClass('active');
  }

  $(dropdownContent).show('slow');
}

function handleSidebarMenuState($this) {
  if ($($this).hasClass('active')) {
    $($this).removeClass('active');

    $($this).find('img').addClass('filter-c-blue');
    $($this).find('span.float-end img').addClass('filter-c-chevron-left');
    $($this).find('span.float-end img').removeClass('rotated-180');
    $($this).find('span.float-end img').addClass('rotated-90');
  } else {
    $($this).addClass('active');

    $($this).find('img').removeClass('filter-c-blue');
    $($this).find('span.float-end img').removeClass('filter-c-chevron-left');
    $($this).find('span.float-end img').removeClass('rotated-90');
    $($this).find('span.float-end img').addClass('rotated-180');
  }

  let dropdownContent = $($this).siblings(".sidebar-dropdown-container")[0];

  if ($(dropdownContent).css("display") === "block") {
    $(dropdownContent).hide('slow');
  } else {
    $(dropdownContent).show('slow');
  }
}

function initSidebarMenuState() {
  let current_location = location.href;
  let current_pathname = location.pathname;
  let elements = $('.sidebar').find('.s-item');

  for (let i = 0; i < elements.length; i++) {
    let dropdown_content_items = $(elements[i]).find('.sidebar-dropdown-container ul li');
    let $dropdown_btn = $(elements[i]).find('.sidebar-dropdown-btn');

    let s_item_root_link = $(elements[i]).find('a.s-item-a-root')[0];
    if (s_item_root_link != undefined) {
      let item_pathname = $(s_item_root_link).attr('href');

      if (current_pathname.includes(item_pathname)) {
        openSidebarItemState($dropdown_btn);
        return false;
      }
    }

    for (let j = 0; j < dropdown_content_items.length; j++) {
      let item = dropdown_content_items[j];
      let item_href = $(item).find('a').attr('href').split("?")[0];

      if (current_location.indexOf(item_href) != -1) {
        openSidebarItemState($dropdown_btn, dropdown_content_items, item);
        return false;
      }
    }
  }
}

$(document).on('click', '.js-btn-add-admin', function (e) {
  e.preventDefault();

  $.ajax({
    url: '/admins/new',
    method: 'GET',
    data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
  }).done(function () {
  });
});

$(document).on('click', '.s-logout-link', function (e) {
  e.preventDefault();

  let close_dialog = 'キャンセル';
  let submit_dialog = 'OK';
  let title = 'ログアウトしますか？';

  $('#confirm-modal').find('p.message-content').html(title);
  $('#confirm-modal').find('.close-dialog').html(close_dialog);
  $('#confirm-modal').find('.logout-btn').removeClass('d-none');
  $('#confirm-modal').find('.submit-dialog').addClass('d-none');

  $('#confirm-modal').modal('show');
});
