// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start();
Turbolinks.start();
ActiveStorage.start();

import "bootstrap/dist/js/bootstrap"
import "bootstrap/dist/css/bootstrap"
import "../stylesheets/application.scss"

require("jquery");
require("jquery-ui");
require('jquery-ui/ui/widgets/sortable');
import * as bootstrap from 'bootstrap'
import "bootstrap-datepicker"

const images = require.context('../images', true);

import "./customs.js"
import "./custom_confirm.js"
import "./custom_control.js"
import "./popover_custom.js"
import "./user_managements.js"
import "./setting_configs.js"
import "./faqs.js"
import "./best_performers.js"
import "./notification_managements.js"
import "./channel_managements.js"

import JQuery from 'jquery'
window.$ = window.JQuery = JQuery;

var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl)
});

$(document).on('ready turbolinks:load', function(){
  $('[data-toggle="tooltip"]').tooltip();

  $(".sortable").sortable({
    items: ".item-row:not(.unsortable)",
    update: function (event, ui) {
      let sortable_wrapper = ui.item.parents('.sortable');
      let items = sortable_wrapper.find('.item-row');

      for (let i = 0; i < items.length; i++) {
        let item_index = i + 1;

        $(items[i]).find('.sort-no').text(item_index);
      }
    }
  });
});
