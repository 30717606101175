$(document).on('click', '.dropdown button', function (e) {
  // $(this).closest('.dropdown').find('.dropdown-menu').toggleClass('show');
  $(this).dropdown("toggle");
});

$(document).on('change', '.js-select-filter', function (e) {
  let value = $(this).val();

  if($(this).is(':checked')) {
    $('#' + value).closest('.col-2').removeClass('d-none');
  } else {
    $('#' + value).closest('.col-2').addClass('d-none');
    $('#' + value).val('');
    $('.js-all-filter').prop('checked', false);
  }
});

$(document).on('change', '.js-all-filter', function (e) {
  if($(this).is(':checked')) {
    $('.js-select-filter').prop('checked', true).change();
  } else {
    $('.js-select-filter').prop('checked', false).change();
  }
});
