import $ from "jquery";

$(document).on('click', '.js-btn-add-channel', function (e) {
  e.preventDefault();

  $.ajax({
    url: '/channel_managements/new',
    method: 'GET',
    data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
  }).done(function () {
  });
});

$(document).on('input', '.js-url-form', function (e) {
  $(':input[type="submit"]').prop('disabled', true);
  if ($(this).val()){
    $.ajax({
      url: `/api/v1/channels/channel-information`,
      method: 'GET',
      data: {'url': $(this).val()}
    }).done(function (res) {
      var data = res.data;
      if(res.statusCode == 200) {
        $('.js-error-channel-url').hide();
        $('.js-channel-info-preview').show();
        $('.js-channel-info-preview .channel-name').text(data.channel_name);
        $('.js-channel-info-preview .channel-description').text(data.channel_description);
        $('.js-channel-info-preview .channel-image-thumbnail').html(`<img src=${data.channel_thumbnail} />`)
  
        $('.js-channel-modal-hidden-data .js-channel-name').val(data.channel_name);
        $('.js-channel-modal-hidden-data .js-channel-url').val(data.channel_url);
        $('.js-channel-modal-hidden-data .js-channel-short-name').val(data.channel_short_name);
        $('.js-channel-modal-hidden-data .js-subscriber-number').val(data.subscriber_number);
        $('.js-channel-modal-hidden-data .js-channel-thumbnail').val(data.channel_thumbnail);
        $('.js-channel-modal-hidden-data .js-channel-id').val(data.channel_id);
        $('.js-channel-modal-hidden-data .js-channel-videos-count').val(data.videos_count);
  
        $.ajax({
          url: `/api/v1/channels/channel-videos`,
          method: 'GET',
          data: {'channel_id': data.channel_id}
        }).done(function (res) {
          var data = res.data.objects;
          $('.channel-video-preview-block').html('');
          $.each(data, function(idx, el){
            $('.channel-video-preview-block').append(`<div class='col-3 channel-video-preview'><img src=${el} /></div>`)
          })
          $(':input[type="submit"]').prop('disabled', false);
        });
      } else {
        $('.js-channel-info-preview').hide();
        $('.js-error-channel-url').text(res.errors.url[0]);
        $('.js-error-channel-url').show();
      }
    });
  }
});

$(document).on('input', '.js-video-url-form', function (e) {
  var channelId = $('#video_priority_video_channel_id').val();
  $(':input[type="submit"]').prop('disabled', true);

  if ($(this).val()) {
    $.ajax({
      url: `/api/v1/videos/information`,
      method: 'GET',
      data: {'url': $(this).val(), 'channel_id': channelId}
    }).done(function (res) {
      var data = res.data;
      if(res.statusCode == 200) {
        $('.js-error-video-url').hide();
        $('.new-form-preview').show();
        $('.js-video-info-preview .video-name').text(data.name);
        $('.js-video-info-preview .video-description').text(data.video_description);
        $('.js-video-info-preview .video-preview-block').html(`<img src=${data.video_thumbnail} />`)
        $('.js-video-info-preview .video-channel-thumbnail').html(`<img src=${data.channel_thumbnail} />`)
  
        $('.js-video-hidden-data .js-video-name').val(data.name);
        $('.js-video-hidden-data .js-video-publish-at').val(data.publish_at);
        $('.js-video-hidden-data .js-video-thumbnail').val(data.video_thumbnail);
        $('.js-video-hidden-data .js-video-view-count').val(data.view_count);
        $('.js-video-hidden-data .js-channel-id').val(data.channel_id);
        $(':input[type="submit"]').prop('disabled', false);
      } else {
        $('.new-form-preview').hide();
        $('.js-error-video-url').text(res.errors.url[0]);
        $('.js-error-video-url').show();
      }
    });
  }
});

$(document).on('click', '.js-btn-channel-sort', function (e) {
  e.preventDefault();
  $(this).addClass('hidden');
  $('.js-btn-save-channel-sort').removeClass('hidden');
  $('.sortable .item-row').removeClass('unsortable');
});

$(document).on('click', '.js-btn-save-channel-sort', function (e) {
  e.preventDefault();

  var data = [];
  $.each($('.item-row'), function(e){
    var el = $('.item-row')[e];
    var id = $(el).data('object-id');
    var ord = $(el).find('.sort-no').text();
    data.push({'id': id, 'no': ord})
  });

  var dataJson = JSON.stringify(data);
  $.ajax({
    url: '/channel_managements/rearrange',
    method: 'POST',
    data: {'data': dataJson}
  }).done(function () {
  });
});

$(document).on('click', '.js-btn-video-sort', function (e) {
  e.preventDefault();
  $(this).addClass('hidden');
  $('.js-btn-save-video-sort').removeClass('hidden');
  $('.sortable .item-row').removeClass('unsortable');
});

$(document).on('click', '.js-btn-save-video-sort', function (e) {
  e.preventDefault();

  var data = [];
  $.each($('.item-row'), function(e){
    var el = $('.item-row')[e];
    var id = $(el).data('object-id');
    var ord = $(el).find('.sort-no').text();
    data.push({'id': id, 'no': ord})
  });

  var dataJson = JSON.stringify(data);
  $.ajax({
    url: '/videos/rearrange',
    method: 'POST',
    data: {'data': dataJson}
  }).done(function () {
  });
});