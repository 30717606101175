import $ from 'jquery'

$(document).on('click', '.js-btn-add-faq', function (e) {
  e.preventDefault();

  $.ajax({
    url: '/faqs/new',
    method: 'GET',
    data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
  }).done(function () {
  });
});

$(document).on('click', '.js-btn-edit-faq', function (e) {
  e.preventDefault();
  let id = $(this).data('item-id');

  $.ajax({
    url: '/faqs/' + id + '/edit',
    method: 'GET',
    data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
  }).done(function () {
  });
});

$(document).on('click', '.js-btn-faq-sort', function (e) {
  e.preventDefault();
  $(this).addClass('hidden');
  $('.js-btn-save-faq-sort').removeClass('hidden');
  $('.sortable .item-row').removeClass('unsortable');
});

$(document).on('click', '.js-btn-save-faq-sort', function (e) {
  e.preventDefault();

  var data = [];
  $.each($('.item-row'), function(e){
    var el = $('.item-row')[e];
    var id = $(el).data('object-id');
    var ord = $(el).find('.sort-no').text();
    data.push({'id': id, 'no': ord})
  });

  var dataJson = JSON.stringify(data);
  $.ajax({
    url: '/faqs/rearrange',
    method: 'POST',
    data: {'data': dataJson}
  }).done(function () {
  });
});