import $ from "jquery";

$(document).on('click', '.js-btn-add-setting-config', function (e) {
  e.preventDefault();

  $.ajax({
    url: '/content_managements/new',
    method: 'GET',
    data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
  }).done(function () {
  });
});

$(document).on('click', '.js-btn-edit', function (e) {
  e.preventDefault();
  let id = $(this).data('item-id');
  let cl = $(this).data('item-cl');

  if(cl == 'setting_configs') {
    cl = 'content_managements';
  }

  $.ajax({
    url: '/' + cl + '/' + id + '/edit',
    method: 'GET',
    data: {'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
  }).done(function () {
  });
});

$(document).on('click', '.js-btn-content-sort', function (e) {
  e.preventDefault();
  $(this).addClass('hidden');
  $('.js-btn-save-content-sort').removeClass('hidden');
  $('.sortable .item-row').removeClass('unsortable');
});

$(document).on('click', '.js-btn-save-content-sort', function (e) {
  e.preventDefault();

  var data = [];
  $.each($('.item-row'), function(e){
    var el = $('.item-row')[e];
    var id = $(el).data('object-id');
    var ord = $(el).find('.sort-no').text();
    data.push({'id': id, 'no': ord})
  });

  var dataJson = JSON.stringify(data);
  $.ajax({
    url: '/content_managements/rearrange',
    method: 'POST',
    data: {'data': dataJson}
  }).done(function () {
  });
});